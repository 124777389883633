<template>
    <div>
        <v-row dense no-gutters>
            <v-col v-for="(item, i) in internalItems" :key="i" cols="3">
                <v-checkbox hide-details="auto"
                            v-model="internalValue"
                            :label="item"
                            :value="item"
                            :disabled="disabled"
                            :error="error"
                            :readonly="readonly"
                            color="primary"
                            class="my-0 py-0" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: "checkboxgroup",
        props: {
            options: String,
            value: String,
            error: Boolean,
            disabled: Boolean,
            readonly: Boolean,
            label: String,
            editable: Boolean,
            seperator: String,

        },
        data()
        {
            return {
                internalItems: [],   // []; // Her bir index i�in bir value 0=> uncheck 1= checked
                internalValue: [],
                internelseperator: ',',
            };
        },
        mounted()
        {
            console.log(" Checkboxgroup mounted options:", this.options, " Value:", this.value);
            this.internelseperator = this.seperator;
            if (!this.seperator || this.seperator == '') this.internelseperator = ',';
            console.log("checkbox Mounted", " options:", this.options, " value:", this.value);  
            this.internalItems = this.options.split(this.internelseperator);
            console.log("InternalItems:", this.internalItems);
            if (this.value.trim() != "")
                this.internalValue = this.value.split(this.internelseperator);
            else
                this.internalValue = [];
            console.log("internalValue", this.internalValue);
        },
        watch:
        {
            internalValue: function (n, o)
            {
                console.log("internalValue watch new:", n, " old:", o);
                this.internelseperator = this.seperator;
                if (!this.seperator || this.seperator == '') this.internelseperator = ',';
                var val = "";
                if (n.length > 0)
                    val = n.join(this.internelseperator);
                else
                    val = "";

                console.log(" Watch emit", val)
                this.$emit("change", val);
            },
            value: function (n, o)
            {
                console.log("checkboxgroup value changed", n);
                if (o == n) return;
                this.internelseperator = this.seperator;
                if (!this.seperator || this.seperator == '') this.internelseperator = ',';
                if (n.trim() == "") this.internalValue  = []
                else this.internalValue = this.value.split(this.internelseperator);

                console.log(" checkboxgroup watch output internalValue", this.internalValue);
            },
            options: function (n, o) {
                console.log(" Checkboxgroup mounted options:", this.options, " Value:", this.value);
                this.internelseperator = this.seperator;
                if (!this.seperator || this.seperator == '') this.internelseperator = ',';
                console.log("checkbox Mounted", " options:", this.options, " value:", this.value);
                this.internalItems = this.options.split(this.internelseperator);
                console.log("InternalItems:", this.internalItems);
            }
        }
    }
</script>

<style scoped>
</style>