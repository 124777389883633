<template>
    <v-main fluid>
        <v-container>
            <v-row class="px-5 mb-10" justify="center">
                <v-expansion-panels tile multiple focusable v-model="openallpanel">
                    <v-expansion-panel v-for="group in GetGroups" :key="group.key">
                        <v-expansion-panel-header disable-icon-rotate ripple class="subtitle-1 font-weight-bold">
                            {{group.header}}
                            <template v-slot:actions>
                                <v-icon :color="group.completed ? 'green': 'red'">mdi-{{group.completed ? 'check': 'close'}}-circle</v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-0">
                            <surveyitem :survey="GetGroupSurveyItems(group.id)" @change="UpdateSurvey($event)"></surveyitem>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>

            <v-row class="mb-10" justify="center">
                <v-col>
                    <grid groupby=''
                          :columns="GetExdataColumns('tedarik_kontakkisiler')"
                          :data="GetExdataItems('tedarik_kontakkisiler')"
                          :header="GetExtdataHeader('tedarik_kontakkisiler')"
                          tableName="tedarik_kontakkisiler"
                          @change="UpdateExtData($event, 'tedarik_kontakkisiler')"
                          @changeAttribute="ChangeAttribute($event,'tedarik_kontakkisiler')"
                          @rowselected="Selected($event, 'tedarik_kontakkisiler')"
                          @rowclicked="RowClicked($event, 'tedarik_kontakkisiler')"
                          @SearchInputChanged="SearchInputChanged($event, 'tedarik_kontakkisiler')"
                          @RowButtonClicked="RowButtonClicked($event, 'tedarik_kontakkisiler')"
                          @AddRow="AddRow2ExtData($event, 'tedarik_kontakkisiler')"
                          :attributes="GetExtdataAttributes('tedarik_kontakkisiler')"
                          @HeaderButtonClicked="HeaderButtonClicked($event, 'tedarik_kontakkisiler')"
                          :clickrow="1 == 1"
                          :searchable="false"
                          addrow="AddUser"
                          delrow="Sil"
                          :showselect="true"
                          :maxLen="GetExdata('tedarik_kontakkisiler').maxLen" />
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>

<script>
    import Utilities from '@/Utilities.js';
    import Vue from 'vue';
    import surveyitem from '@/components/SurveyItem.vue';

    Vue.component('surveyitem', surveyitem);

    export default {
        name: "Test",
        data: function ()
        {
            return {
                survey: [],
                openallpanel: []
            };
        },
        mounted: function ()
        {
            console.log("TEST MOUNTED");

            var payload = {};
            payload.action = "GetSurvey"
            payload.data = [{ id: 1 }];
            this.$store.dispatch("RunTime", payload)
                .then(response =>
                {
                    Utilities.log("Runtime  success result:", response);
                    this.survey = response.data.RunTimeResult;
                    this.openallpanel = Array(this.survey.length - 0 + 1).fill().map((_, idx) => 0 + idx);
                })
                .catch(err =>
                {
                    Utilities.log("Runtime erroro result:", err);
                });
        },

        methods:
        {
            UpdateSurvey: function (payload)
            {
                try
                {
                    console.log("UpdateSurvey in test:", payload);

                    var isCompleted = true;
                    var groupId = payload.group;

                    var questions = this.survey.filter(x => x.group == groupId);

                    for (var questionId in questions)
                    {
                        if (questions[questionId].isAnswered == '0' || questions[questionId].isAnsweredText == '0')
                        {
                            isCompleted = false;
                            break;
                        }
                    }

                    this.GetGroups[groupId - 1].completed = isCompleted;
                }
                catch (e)
                {

                }
            }
        },
        computed:
        {
            GetGroups: function ()
            {
                var groups = [];
                var grouplist = ",";
                for (var i = 0; i < this.survey.length; i++)
                {
                    if (grouplist.indexOf(',' + this.survey[i].group + ',') != -1) continue;
                    grouplist += this.survey[i].group + ',';
                    groups.push({ id: this.survey[i].group, header: this.survey[i].groupheader, completed: false });
                }
                groups.sort();
                return groups;
            },
            GetGroupSurveyItems()
            {
                var self = this;
                return group =>
                {
                    var items = [];
                    for (var i = 0; i < this.survey.length; i++)
                    {
                        if (this.survey[i].group != group) continue;
                        items.push(this.survey[i]);
                    }
                    return items;
                };
            }
        },
        watch: {
            GetGroups: {
                deep: true,
                handler(n, o)
                {
                    console.log('Sth happende!', n, o);
                }
            }
        }
    }
</script>

<style scoped>
</style>