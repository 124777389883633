<template>
    <div>
        <v-radio-group v-model="internalValue" row class="my-0 py-0" hide-details="auto">
                <v-radio v-for="(item, i) in internalOptions" :key="i"
                         hide-details="auto"
                         :value="item"
                         :label="item"
                         :disabled="disabled"
                         :error="error"
                         :readonly="readonly"
                         color="secondary"
                         class="m-0 py-0"
                         style="width: 25%"
                         dense />
        </v-radio-group>
    </div>
</template>

<script>

    import Utilities from '@/Utilities.js'
    export default {
        name: "radiobuttongroup",
        props: {
            options: String,
            value: String,
            error: Boolean,
            disabled: Boolean,
            readonly: Boolean,
            label: String,
            seperator: String,

        },
        data()
        {
            return {
                internalValue: null,
                internalOptions: null,
                internalseperator: ',',
            };
        },
        mounted()
        {
            console.log("Radio Button Group Mounted", " params:", this.options, " value:", this.value);
            this.internalValue = this.value;

            if (!this.seperator || this.seperator == '') this.internalseperator = ',';
            else this.internalseperator = this.seperator;
            this.internalOptions = this.options.split(this.internalseperator);
            console.log("internalValue", this.internalValue);
        },
        watch:
        {
            internalValue: function (n, o)
            {
                console.log("internalValue watch new:", n, " old:", o);
                if (o == n) return;
                this.$emit("change", n);
            },
            value: function (n, o)
            {
                console.log("checkboxgroup value changed", n);
                this.internalValue = this.value;
                console.log(" checkboxgroup watch output internalValue", this.internalValue);
            },
            options: function (n, o) {
                console.log(" Checkboxgroup mounted options:", this.options, " Value:", this.value);
                this.internelseperator = this.seperator;
                if (!this.seperator || this.seperator == '') this.internelseperator = ',';
                console.log("checkbox Mounted", " options:", this.options, " value:", this.value);
                this.internalItems = this.options.split(this.internelseperator);
                console.log("InternalItems:", this.internalItems);
            }
        }
    }
</script>

<style>
    
</style>