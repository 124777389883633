<template>
    <div>
        <div v-for="(s, i) in survey" :key="i">
            <v-row class="m-0 p-0 pt-3">
                <v-col cols="12">
                    <p class="subtitle-1 font-weight-bold py-0 my-0" :class="s.isAnswered ? 'green--text' : 'red--text'">
                        <v-icon v-if="s.isAnswered" left color="green white--text">mdi-check-circle</v-icon>
                        <v-icon v-else left color="red white--text">mdi-close-circle</v-icon>
                        {{s.description}}
                    </p>
                    <p class="caption py-0 my-0 mb-4">{{s.subtitle}}</p>
                    <radiobuttongroup v-if="s.optiontype=='radio'"
                                      :disabled="disabled"
                                      :options="s.options"
                                      :value="s.optionvalue"
                                      :seperator="s.seperator"
                                      :error="s.error"
                                      :readonly="s.readonly"
                                      :editable="s.editable"
                                      @change="OptionChanged($event,s.id)" />

                    <checkboxgroup v-if="s.optiontype=='checkbox'"
                                   :disabled="disabled"
                                   :options="s.options"
                                   :value="s.optionvalue"
                                   :seperator="s.seperator"
                                   :error="s.error"
                                   :readonly="s.readonly"
                                   :editable="s.editable"
                                   @change="OptionChanged($event,s.id)"></checkboxgroup>

                    <v-textarea v-if="s.textexists == 'E'"
                                :disabled="disabled"
                                :value="s.text"
                                outlined
                                hide-details="auto"
                                color="secondary"
                                placeholder="Açıklama"
                                :class="s.optiontype == '' ? 'mt-0' : 'mt-3'"
                                @change="ChangeText($event,s.id)">
                    </v-textarea>
                </v-col>
            </v-row>
            <v-divider></v-divider>
        </div>
    </div>
</template>

<script>

    import Vue from 'vue';
    import checkboxgroup from '@/components/CheckBoxGroup.vue';
    Vue.component('checkboxgroup', checkboxgroup);
    import radiobuttongroup from '@/components/RadioButtonGroup.vue';
    Vue.component('radiobuttongroup', radiobuttongroup);


    export default {
        name: "SurveyItem",
        props: {
            survey: Array,
            disabled: Boolean,
        },
        data()
        {
            return {
                internaloptions: '',   // []; // Her bir index için bir value 0=> uncheck 1= checked
                internaloptionvalue: '',
                internaltext: '',
                internalfile: '',
                internaldescription: '',
                internalsurvey: [],
            };
        },
        mounted()
        {
            this.internalsurvey = [...this.survey];
        },
        watch:
        {
            survey: function (n, o)
            {
                this.internalsurvey = [...this.survey];
            }
        },
        methods:
        {
            OptionChanged(value, id)
            {
                console.log("Option changed val:" + value + " id:" + id, " options", this.internaloptions);
                var index = 0;
                for (var i = 0; i < this.survey.length; i++)
                {
                    if (this.survey[i].id == id)
                    {
                        index = i;
                        break;
                    }
                }
                this.internalsurvey[index].optionvalue = value;
                this.PrepareIsAnswered(index);
                var payload = {};
                payload.columnName = 'optionvalue';
                payload.columnValue = { fieldValue: value };
                payload.columnValue.column = { outputs: '' };
                payload.selectedOject = value;
                payload.rowdata = this.internalsurvey[index];

                //payload.optionvalue = this.internalsurvey[index].optionvalue;
                //payload.text = this.internalsurvey[index].text;
                //payload.file = this.internalsurvey[index].file;
                //payload.index = id;
                //payload.group = this.internalsurvey[index].group;
                this.$emit("change", payload);
            },

            ChangeText(value, id)
            {
                console.log("text changed val:" + value + " index:" + id);
                var index = 0;
                for (var i = 0; i < this.survey.length; i++)
                {
                    if (this.survey[i].id == id)
                    {
                        index = i;
                        break;
                    }
                }
                this.internalsurvey[index].text = value;
                this.PrepareIsAnswered(index);
                var payload = {};
                payload.columnName = 'text';
                payload.columnValue = { fieldValue: value };
                payload.columnValue.column = { outputs: '' };
                payload.selectedOject = value;
                payload.rowdata = this.internalsurvey[index];
                this.$emit("change", payload);
            },

            PrepareIsAnswered(index)
            {
                if (this.internalsurvey[index].optiontype != '')
                {
                    this.internalsurvey[index].isAnswered = (this.internalsurvey[index].optionvalue.length > 0) ? true : false;
                }
                else
                {
                    this.internalsurvey[index].isAnswered = (this.internalsurvey[index].text.length > 0) ? true : false;
                }
            }
        }
    }
</script>

<style scoped>
</style>